<template>
	<div class="mining-pool">
		<div class="head">
			<div class="head-img">
				<img src="@/assets/minningpoolpic.png" alt="">
			</div>
			<div class="head-right">
				<p class="head-title">{{ $t('Mining Pool') }}</p>
				<p class="head-title__sub">{{ $t('Staking NFT to mining AMT token') }}</p>
				<p class="head-value">{{ totalSupply / 100 }}</p>
				<p class="head-tip">{{ $t('Current Minnig Power') }}</p>
				<a href="https://bscscan.com/address/0xd047542358Ef1A7705C95a7C0189b5dD410174a7" target="_blank">{{ $t('Smart Contract') }}</a>
			</div>
		</div>
		<div class="card-list">
			<div class="card-item">
				<p class="card-title">{{ $t('Mining Balance') }}</p>
				<p class="card-p">{{ $t('Revoke can get back your NFT, but your mining about NB token will be burned.') }}</p>
				<p class="card-volume">
					<img src="@/assets/logo.png" alt="">
					<span>{{ Number(myPending).toFixed(4) }}</span>
				</p>
			</div>
			<div class="card-item">
				<p class="card-title">{{ $t('My Balance') }}</p>
				<p class="card-p">{{ $t('Burn your NFT to get the Mining NB token.') }}</p>
				<div class="card-right">
					<div class="card-volume">
						<img src="@/assets/logo.png" alt="">
						<span>{{ Number(myToken).toFixed(4) }}</span>
					</div>
					<div class="card-link">
						<span>{{ $t('Exchange') }}: </span>
						<a href="https://swap.amateras.io/#/swap" target="_blank">{{ $t('Amateras') }}</a>
					</div>
				</div>
			</div>
		</div>

		<div class="m-mining_staking" v-if="is_mobile">
			<div class="m-mining_staking-title">{{ $t('My Staking') }}</div>

			<div class="m-mining_staking-item" v-for="(item, index) in staking_cards" :key="index">
				<div class="m-mining_staking-index">{{ index + 1 }}</div>
				<div class="m-mining_staking-content">
					<div class="m-mining_staking-row">
						<div>{{ $t('NAME') }}</div>
						<div>{{ card_list[item.key].zhHans }}</div>
					</div>
					<div class="m-mining_staking-row">
						<div>NUM</div>
						<div>{{ item.num }}</div>
					</div>
<!--					<div class="m-mining_staking-row">-->
<!--						<div>{{ $t('SHARE') }}</div>-->
<!--						<div>{{ item.rate }}%</div>-->
<!--					</div>-->
					<div class="m-mining_staking-btn">
						<v-btn
								class="mr-5"
								color="#EB6930"
								outlined
								rounded
								small
								@click="tableDialog(0, item)"
						>{{ $t('REVOKE') }}</v-btn>
						<v-btn
								color="#EB6930"
								outlined
								rounded
								small
								@click="tableDialog(1, item)"
						>{{ $t('BURN') }}</v-btn>
					</div>
				</div>
			</div>
		</div>

		<div class="staking" v-show="!is_mobile">
			<p class="staking-title">{{ $t('My Staking') }}</p>
			<table>
				<tr>
					<th width="50"></th>
					<th width="250">{{ $t('NAME') }}</th>
					<th width="80">{{ $t('NUM') }}</th>
<!--					<th width="120">{{ $t('SHARE') }}</th>-->
					<th></th>
				</tr>
				<tr v-for="(item, i) in staking_cards" :key="i">
					<td>{{ i + 1 }}</td>
					<td>{{ card_list[item.key].zhHans }}</td>
					<td>{{ item.num }}</td>
<!--					<td>{{ item.rate }}%</td>-->
					<td>
						<v-btn
							class="mr-5"
							color="#2E3843"
							rounded
							@click="tableDialog(0, item)"
						>{{ $t('REVOKE') }}</v-btn>
						<v-btn
							color="#2E3843"
							rounded
							@click="tableDialog(1, item)"
						>{{ $t('BURN') }}</v-btn>
					</td>
				</tr>
				<tr v-show="staking_cards.length === 0">
					<td colspan="5" class="text-center" style="color: #67717A;">{{ $t('No Data') }}</td>
				</tr>
			</table>
		</div>

		<v-dialog v-model="m_table_dialog">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ table_context[table_index].title }}</span>
					<v-btn @click="m_table_dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content">
					<div class="m-mining_remove-info">
						<div class="m-mining_remove-pic">
							<img class="m-mining_remove-bg" src="@/assets/bg_revoke.svg" alt="">
							<img class="m-mining_remove-img" :src="getCardImgUrl(current_item.key)" alt="" v-if="current_item">
						</div>
						<div class="m-mining_remove-text">
							<div class="name" v-if="current_item">{{ card_list[current_item.key].zhHans }}</div>
							<p>{{
									table_index === 0 ? $t('Revoke can get back your NFT, but your mining about num AMT token will be burned.', {num: Number(card_earnings).toFixed(4)}) : $t('Burn the NFT to get the mining num AMT token.', {num: Number(card_earnings).toFixed(4)})
								}}</p>
						</div>
					</div>
					<div class="m-mining_remove-label">NUM</div>
					<input class="m-mining_remove-input" type="number" placeholder="0" v-model="remove_number">
					<v-btn
							style="height: 44px !important;"
							color="#EB6930"
							rounded
							block
							:loading="table_loading"
							@click="table_method(current_item.ids)"
					>{{ table_context[table_index].btn_text }}
						<template v-slot:loader>
							<span>{{ table_index === 0 ? 'Removing...' : 'Burning...' }}</span>
						</template>
					</v-btn>
				</div>
			</div>
		</v-dialog>

		<v-dialog v-model="table_dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ table_context[table_index].title }}</span>
					<v-btn @click="table_dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content NftDetails-dialog">
					<div class="NftDetails-dialog__card">
						<img src="@/assets/bg_revoke.svg" alt="">
						<img class="content-img" :src="getCardImgUrl(current_item.key)" alt="" v-if="current_item">
					</div>
					<div class="NftDetails-dialog__right">
						<div class="name" v-if="current_item">{{ card_list[current_item.key].zhHans }}</div>
						<p class="mb-10">{{
								table_index === 0 ? $t('Revoke can get back your NFT, but your mining about num AMT token will be burned.', {num: Number(card_earnings).toFixed(4)}) : $t('Burn the NFT to get the mining num AMT token.', {num: Number(card_earnings).toFixed(4)})
							}}</p>
						<div class="label mb-4">{{ $t('NUM') }}</div>
						<div class="input">
							<input type="number" placeholder="0" v-model="remove_number">
						</div>
						<v-btn
							style="width: 298px;"
							class="mt-10"
							color="#EB6930"
							rounded
							large
							:loading="table_loading"
							@click="table_method(current_item.ids)"
						>{{ table_context[table_index].btn_text }}
							<template v-slot:loader>
								<span>{{ table_index === 0 ? $t('Removing...') : $t('Burning...') }}</span>
							</template>
						</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { myStakingCards, nftStakingBurn, nftStakingWithdraw, amtINfo, stakingPoolInfo, pendingBatch } from '@/utils/cardFactory'
import { mapState } from 'vuex'
import CardList from '@/data/card'
import { getCardImgUrl } from '@/utils/tools'
import eventBus from '@/utils/eventBus'

export default {
	name: 'MiningPool',
	data () {
		return {
			current_item: null,
			staking_cards: [],
			batch_index: 0,
			table_dialog: false,
			table_index: 0,
			table_loading: false,
			myPending: 0,
			myToken: 0,
			card_list: CardList,
			remove_number: 1,
			totalSupply: 0,
			m_table_dialog: false,
			card_earnings: 0,
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		table_context () {
			return [
				{
					title: this.$t('REVOKE'),
					btn_text: this.$t('REVOKE'),
				},
				{
					title: this.$t('BURN'),
					btn_text: this.$t('BURN'),
				},
			]
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	mounted () {
		if (this.ADDRESS) {
			this._poolInfo()
			this._myStakingCards()
			this._myCardNum()
		}
		eventBus.$on('connect-event', (res) => {
			this._poolInfo()
			this._myStakingCards()
			this._myCardNum()
		})
	},
	watch: {
		remove_number (val) {
			if (Number(val) > 0) {
				this._getEarning(this.current_item.ids.slice(0, val))
			}
		},
	},
	methods: {
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		async _myCardNum () {
			const info = await amtINfo(this.ADDRESS)
			console.log(info)
			this.myPending = info.pending
			this.myToken = info.balance
		},
		async _myStakingCards () {
			this.staking_cards = await myStakingCards(this.ADDRESS)
		},
		async _getEarning (ids) {
			const res = await pendingBatch(ids)
			this.card_earnings = this.web3.utils.fromWei(res, 'ether')
		},
		async tableDialog (index, item) {
			await this._getEarning([item.ids[0]])
			this.current_item = item
			this.table_index = index
			if (this.is_mobile) {
				this.m_table_dialog = true
			} else {
				this.table_dialog = true
			}
		},
		// 销毁
		async burn (tokenIds) {
			if (Number(this.remove_number) <= 0) return
			this.table_loading = true
			try {
				await nftStakingBurn(this.ADDRESS, tokenIds.slice(0, this.remove_number))
				this.$toast.success(this.$t('Successful burning'))
				this.table_loading = false
				this.table_dialog = false
				this._myStakingCards()
				this._myCardNum()
			} catch (err) {
				console.error(err)
				this.table_loading = false
			}
		},
		// 取回
		async withdraw (available) {
			if (Number(this.remove_number) <= 0) return
			this.table_loading = true
			try {
				await nftStakingWithdraw(this.ADDRESS, available)
				this.$toast.success(this.$t('Successfully removed'))
				this.table_loading = false
				this.table_dialog = false
				this._myStakingCards()
				this._myCardNum()
			} catch (error) {
				this.table_loading = false
			}
		},
		// pool信息
		async _poolInfo () {
			this.totalSupply = await stakingPoolInfo()
		},
		table_method (available) {
			if (this.table_index === 0) {
				this.withdraw(available.slice(0, this.remove_number))
			} else {
				this.burn(available)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.m-mining_remove {
	&-input {
		width: 100%;
		margin: 14px 0 28px;
		height: 38px;
		background: #495058;
		border-radius: 20px;
		padding: 0 25px;

		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		color: #FFFFFF;
	}
	&-label {
		font-weight: bold;
		font-size: 12px;
		line-height: 18px;
		color: #E8E8E8;
	}
	&-text {
		p {
			font-size: 12px;
			line-height: 18px;
			color: #E8E8E8;
		}
		.name {
			font-weight: bold;
			font-size: 18px;
			line-height: 27px;
			color: #E8E8E8;
			margin-bottom: 14px;
		}
		flex: 1;
	}
	&-img {
		position: absolute;
	}
	&-pic {
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
		width: 140px;
		height: 175.5px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px;
	}
	&-info {
		display: flex;
		margin-bottom: 16px;
	}
}
// pc
@media (min-width: 960px) {
	.mining-pool {
		.card-p {
			font-size: 14px;
			line-height: 21px;
		}
		.card-item {
			padding: 60px;
		}
		.card-volume {
			img {
				width: 42px;
				margin-right: 16px;
			}
			font-size: 48px;
			line-height: 72px;
		}
		.head {
			padding: 0 130px 0 90px;
		}
		.head-title {
			font-size: 24px;
			line-height: 36px;
		}
		.head-title__sub {
			font-size: 14px;
			line-height: 21px;
		}
		.head-value {
			font-size: 64px;
			line-height: 96px;
		}
		.head {
			a {
				font-size: 14px;
				line-height: 21px;
			}
		}
		.card-title {
			font-size: 24px;
			line-height: 36px;
		}
		.card-link {
			font-size: 14px;
			line-height: 21px;
		}
	}
}
// mobile
@media (max-width: 960px) {
	.mining-pool {
		.head-tip {
			font-size: 12px;
		}
		.card-link {
			font-size: 12px;
			line-height: 18px;
		}
		.card-title {
			font-size: 18px;
			line-height: 27px;
		}
		.head {
			a {
				font-size: 12px;
				line-height: 18px;
			}
		}
		.head-value {
			font-size: 20px;
			line-height: 30px;
			padding: 18px 0 8px;
		}
		.head-title__sub {
			font-size: 12px;
			line-height: 18px;
		}
		.head-title {
			font-size: 18px;
			line-height: 27px;
		}
		.head {
			padding: 25px;
		}
		.card-volume {
			img {
				width: 22px;
				margin-right: 8px;
			}
			font-size: 24px;
			line-height: 36px;
		}
		.card-p {
			font-size: 10px;
			line-height: 15px;
			padding: 8px 0;
		}
		.card-item {
			padding: 28px 25px;
		}
		.card-list {
			flex-direction: column;
		}
	}
}
// common
.batch-dialog {
	&__main {
		.input {
			width: 331px;
			height: 53px;
			background: #495058;
			border-radius: 20px;
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			color: #5C6882;
			padding: 0 25px;
			margin-top: 20px;
		}

		.volume {
			font-weight: bold;
			font-size: 36px;
			line-height: 54px;
			color: #EB6930;
			margin-top: 20px;
		}

		.label {
			font-weight: bold;
			font-size: 14px;
			line-height: 21px;
			color: #E8E8E8;
		}

		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
	}

	&__context {
		background: #495058;
		border-radius: 20px;
		padding: 35px 20px;
		font-size: 14px;
		line-height: 21px;
		color: #E8E8E8;
		margin-bottom: 40px;
	}
}

.mining-pool {
	.card-volume {
		display: flex;
		align-items: center;
	}
	.staking {
		table {
			tr {
				td {
					padding-bottom: 20px;
				}

				th {
					font-weight: bold;
					font-size: 14px;
					line-height: 21px;
					color: #67717A;
					text-align: left;
					padding-bottom: 20px;
				}
			}
		}

		&-btn__list {
			padding: 20px 0 60px;
		}

		&-title {
			span {
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #EB6930;
			}

			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			color: #E8E8E8;
			margin-bottom: 40px;
		}

		background-color: #202831;
		padding: 100px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.card {
		&-right {}

		&-link {
			span {
				color: #FFFFFF;
			}

			a {
				color: #EB6930;
			}
			font-weight: 500;
		}

		&-volume {
			font-weight: bold;
			color: #E8E8E8;
		}

		&-p {
			font-weight: 500;
			color: rgba(232, 232, 232, 0.6);
		}

		&-title {
			font-weight: bold;
			color: #E8E8E8;
		}

		&-item {
			&:last-child {
				background: #59657D;
			}

			&:first-child {
				background: #8D7E68;
			}

			flex: 1;
			height: 278px;
		}

		&-list {
			display: flex;
		}
	}

	.head {
		.head-img {
			flex: 1;
		}
		&-right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&-title__sub {
			font-weight: 500;
			color: #67717A;
		}

		&-value {
			font-weight: bold;
			color: #EB6930;
		}

		&-title {
			font-weight: bold;
			color: #E8E8E8;
			margin-bottom: 6px;
		}

		a {
			font-weight: 500;
			text-align: right;
			color: #EB6930;
		}

		img {
			max-width: 460px;
			width: 100%;
			margin-bottom: -50px;
			z-index: 1;
		}

		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #202831;
	}
}
</style>
